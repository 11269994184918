import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _442485af = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _1d238929 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _72270b1d = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _9644968e = () => interopDefault(import('../pages/design-your-website/index.vue' /* webpackChunkName: "pages/design-your-website/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0b88cc7f = () => interopDefault(import('../pages/feature/index.vue' /* webpackChunkName: "pages/feature/index" */))
const _de783be8 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _40be2432 = () => interopDefault(import('../pages/landing-page/index.vue' /* webpackChunkName: "pages/landing-page/index" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _7afb953f = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "" */))
const _395f2ba4 = () => interopDefault(import('../pages/logout-callback.vue' /* webpackChunkName: "pages/logout-callback" */))
const _71761f7c = () => interopDefault(import('../pages/owner/index.vue' /* webpackChunkName: "pages/owner/index" */))
const _6b1975b1 = () => interopDefault(import('../pages/owner-details/index.vue' /* webpackChunkName: "pages/owner-details/index" */))
const _52932d4f = () => interopDefault(import('../pages/pricing/index.vue' /* webpackChunkName: "pages/pricing/index" */))
const _5c2f60a2 = () => interopDefault(import('../pages/property-manager-about/index.vue' /* webpackChunkName: "pages/property-manager-about/index" */))
const _cfd2c176 = () => interopDefault(import('../pages/recommend/index.vue' /* webpackChunkName: "pages/recommend/index" */))
const _0d6930f4 = () => interopDefault(import('../pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))
const _488c0073 = () => interopDefault(import('../pages/reset-user.vue' /* webpackChunkName: "pages/reset-user" */))
const _6bcfd6ec = () => interopDefault(import('../pages/residents/index.vue' /* webpackChunkName: "pages/residents/index" */))
const _6cfe81d6 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _32337dda = () => interopDefault(import('../pages/success-register/index.vue' /* webpackChunkName: "pages/success-register/index" */))
const _ec699596 = () => interopDefault(import('../pages/verify-email/index.vue' /* webpackChunkName: "pages/verify-email/index" */))
const _217ce4b2 = () => interopDefault(import('../pages/video-library/index.vue' /* webpackChunkName: "pages/video-library/index" */))
const _3ada8bd8 = () => interopDefault(import('../pages/create/AddContact.vue' /* webpackChunkName: "pages/create/AddContact" */))
const _ab97cd6a = () => interopDefault(import('../pages/create/AddMaintenance.vue' /* webpackChunkName: "pages/create/AddMaintenance" */))
const _b9370666 = () => interopDefault(import('../pages/create/AddProperty.vue' /* webpackChunkName: "pages/create/AddProperty" */))
const _7db88ac0 = () => interopDefault(import('../pages/create/AddVendor.vue' /* webpackChunkName: "pages/create/AddVendor" */))
const _9558a4d2 = () => interopDefault(import('../pages/create/CreateHolidayGreetings.vue' /* webpackChunkName: "pages/create/CreateHolidayGreetings" */))
const _7d491641 = () => interopDefault(import('../pages/create/CreateMarketing.vue' /* webpackChunkName: "pages/create/CreateMarketing" */))
const _27818fae = () => interopDefault(import('../pages/create/CreateReport.vue' /* webpackChunkName: "pages/create/CreateReport" */))
const _f8d5d1ac = () => interopDefault(import('../pages/create/EditLeaseDraft.vue' /* webpackChunkName: "pages/create/EditLeaseDraft" */))
const _3a2a7be9 = () => interopDefault(import('../pages/create/EditListingAgreement.vue' /* webpackChunkName: "pages/create/EditListingAgreement" */))
const _2e7c9d6b = () => interopDefault(import('../pages/create/EditPurchaseSale.vue' /* webpackChunkName: "pages/create/EditPurchaseSale" */))
const _08a5b702 = () => interopDefault(import('../pages/create/EditRegistrationLetter.vue' /* webpackChunkName: "pages/create/EditRegistrationLetter" */))
const _7f751d42 = () => interopDefault(import('../pages/create/EditSecurityDocumemt.vue' /* webpackChunkName: "pages/create/EditSecurityDocumemt" */))
const _6d9f36d2 = () => interopDefault(import('../pages/owner/About.vue' /* webpackChunkName: "pages/owner/About" */))
const _1b1304d3 = () => interopDefault(import('../pages/owner/Available.vue' /* webpackChunkName: "pages/owner/Available" */))
const _41266f2a = () => interopDefault(import('../pages/owner/Contact.vue' /* webpackChunkName: "pages/owner/Contact" */))
const _9ec46c78 = () => interopDefault(import('../pages/owner/Services.vue' /* webpackChunkName: "pages/owner/Services" */))
const _31eefb43 = () => interopDefault(import('../pages/owner/Team.vue' /* webpackChunkName: "pages/owner/Team" */))
const _c482e1ae = () => interopDefault(import('../pages/owner/Testimonial.vue' /* webpackChunkName: "pages/owner/Testimonial" */))
const _08321129 = () => interopDefault(import('../pages/owner/Why-us.vue' /* webpackChunkName: "pages/owner/Why-us" */))
const _01a91105 = () => interopDefault(import('../pages/payment/status.vue' /* webpackChunkName: "pages/payment/status" */))
const _becd4f46 = () => interopDefault(import('../pages/create/AddProperty.vue' /* webpackChunkName: "" */))
const _568f0a50 = () => interopDefault(import('../pages/create/AddVendor.vue' /* webpackChunkName: "" */))
const _7cd3fe48 = () => interopDefault(import('../pages/create/AddContact.vue' /* webpackChunkName: "" */))
const _d762588a = () => interopDefault(import('../pages/create/AddMaintenance.vue' /* webpackChunkName: "" */))
const _6819b21c = () => interopDefault(import('../components/AddOwner.vue' /* webpackChunkName: "" */))
const _1d096211 = () => interopDefault(import('../components/AddTenant.vue' /* webpackChunkName: "" */))
const _7c04f807 = () => interopDefault(import('../pages/create/CreateHolidayGreetings.vue' /* webpackChunkName: "" */))
const _52f4ac5e = () => interopDefault(import('../pages/create/CreateMarketing.vue' /* webpackChunkName: "" */))
const _d4b462ce = () => interopDefault(import('../pages/create/CreateReport.vue' /* webpackChunkName: "" */))
const _6dafd19a = () => interopDefault(import('../pages/create/EditLeaseDraft.vue' /* webpackChunkName: "" */))
const _148ba259 = () => interopDefault(import('../pages/create/EditListingAgreement.vue' /* webpackChunkName: "" */))
const _065b084a = () => interopDefault(import('../pages/create/EditPurchaseSale.vue' /* webpackChunkName: "" */))
const _6151fd1c = () => interopDefault(import('../pages/create/EditRegistrationLetter.vue' /* webpackChunkName: "" */))
const _cab2d062 = () => interopDefault(import('../pages/create/EditSecurityDocumemt.vue' /* webpackChunkName: "" */))
const _f561e87c = () => interopDefault(import('../pages/help-center/_name.vue' /* webpackChunkName: "pages/help-center/_name" */))
const _f4ab0210 = () => interopDefault(import('../pages/listing/_id/index.vue' /* webpackChunkName: "pages/listing/_id/index" */))
const _39a789b7 = () => interopDefault(import('../pages/payment/_id.vue' /* webpackChunkName: "pages/payment/_id" */))
const _3e17cdf4 = () => interopDefault(import('../pages/listing/_id/apply-for-rent.vue' /* webpackChunkName: "pages/listing/_id/apply-for-rent" */))
const _4dcc71c0 = () => interopDefault(import('../pages/listing/_id/index_bk.vue' /* webpackChunkName: "pages/listing/_id/index_bk" */))
const _096b7939 = () => interopDefault(import('../pages/dashboard/_type/_component.vue' /* webpackChunkName: "pages/dashboard/_type/_component" */))
const _743c2773 = () => interopDefault(import('../pages/_name/index.vue' /* webpackChunkName: "pages/_name/index" */))
const _681326e4 = () => interopDefault(import('../pages/_name/About.vue' /* webpackChunkName: "pages/_name/About" */))
const _89d38d6c = () => interopDefault(import('../pages/_name/Available.vue' /* webpackChunkName: "pages/_name/Available" */))
const _aaeb553e = () => interopDefault(import('../pages/_name/Contact.vue' /* webpackChunkName: "pages/_name/Contact" */))
const _73b6aa6d = () => interopDefault(import('../pages/_name/Services.vue' /* webpackChunkName: "pages/_name/Services" */))
const _e646cf28 = () => interopDefault(import('../pages/_name/Team.vue' /* webpackChunkName: "pages/_name/Team" */))
const _49c681e0 = () => interopDefault(import('../pages/_name/Testimonial.vue' /* webpackChunkName: "pages/_name/Testimonial" */))
const _5e2d0812 = () => interopDefault(import('../pages/_name/Why-us.vue' /* webpackChunkName: "pages/_name/Why-us" */))
const _b7526f1e = () => interopDefault(import('../pages/_name/alerts/_id.vue' /* webpackChunkName: "pages/_name/alerts/_id" */))
const _4dabbf93 = () => interopDefault(import('../pages/_name/listing/_id/index.vue' /* webpackChunkName: "pages/_name/listing/_id/index" */))
const _625b526a = () => interopDefault(import('../pages/_name/listing/_id/apply-for-rent.vue' /* webpackChunkName: "pages/_name/listing/_id/apply-for-rent" */))
const _ba5206b6 = () => interopDefault(import('../pages/_name/listing/_id/index_bk.vue' /* webpackChunkName: "pages/_name/listing/_id/index_bk" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/callback",
    component: _442485af,
    name: "callback___th"
  }, {
    path: "/contact",
    component: _1d238929,
    name: "contact___th"
  }, {
    path: "/dashboard",
    component: _72270b1d,
    name: "dashboard___th"
  }, {
    path: "/design-your-website",
    component: _9644968e,
    name: "design-your-website___th"
  }, {
    path: "/en",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/feature",
    component: _0b88cc7f,
    name: "feature___th"
  }, {
    path: "/home",
    component: _de783be8,
    name: "home___th"
  }, {
    path: "/landing-page",
    component: _40be2432,
    name: "landing-page___th"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login___th"
  }, {
    path: "/login-callback",
    component: _7afb953f,
    name: "login-callback___th"
  }, {
    path: "/logout-callback",
    component: _395f2ba4,
    name: "logout-callback___th"
  }, {
    path: "/owner",
    component: _71761f7c,
    name: "owner___th"
  }, {
    path: "/owner-details",
    component: _6b1975b1,
    name: "owner-details___th"
  }, {
    path: "/pricing",
    component: _52932d4f,
    name: "pricing___th"
  }, {
    path: "/property-manager-about",
    component: _5c2f60a2,
    name: "property-manager-about___th"
  }, {
    path: "/recommend",
    component: _cfd2c176,
    name: "recommend___th"
  }, {
    path: "/reset-password",
    component: _0d6930f4,
    name: "reset-password___th"
  }, {
    path: "/reset-user",
    component: _488c0073,
    name: "reset-user___th"
  }, {
    path: "/residents",
    component: _6bcfd6ec,
    name: "residents___th"
  }, {
    path: "/search",
    component: _6cfe81d6,
    name: "search___th"
  }, {
    path: "/success-register",
    component: _32337dda,
    name: "success-register___th"
  }, {
    path: "/verify-email",
    component: _ec699596,
    name: "verify-email___th"
  }, {
    path: "/video-library",
    component: _217ce4b2,
    name: "video-library___th"
  }, {
    path: "/create/AddContact",
    component: _3ada8bd8,
    name: "create-AddContact___th"
  }, {
    path: "/create/AddMaintenance",
    component: _ab97cd6a,
    name: "create-AddMaintenance___th"
  }, {
    path: "/create/AddProperty",
    component: _b9370666,
    name: "create-AddProperty___th"
  }, {
    path: "/create/AddVendor",
    component: _7db88ac0,
    name: "create-AddVendor___th"
  }, {
    path: "/create/CreateHolidayGreetings",
    component: _9558a4d2,
    name: "create-CreateHolidayGreetings___th"
  }, {
    path: "/create/CreateMarketing",
    component: _7d491641,
    name: "create-CreateMarketing___th"
  }, {
    path: "/create/CreateReport",
    component: _27818fae,
    name: "create-CreateReport___th"
  }, {
    path: "/create/EditLeaseDraft",
    component: _f8d5d1ac,
    name: "create-EditLeaseDraft___th"
  }, {
    path: "/create/EditListingAgreement",
    component: _3a2a7be9,
    name: "create-EditListingAgreement___th"
  }, {
    path: "/create/EditPurchaseSale",
    component: _2e7c9d6b,
    name: "create-EditPurchaseSale___th"
  }, {
    path: "/create/EditRegistrationLetter",
    component: _08a5b702,
    name: "create-EditRegistrationLetter___th"
  }, {
    path: "/create/EditSecurityDocumemt",
    component: _7f751d42,
    name: "create-EditSecurityDocumemt___th"
  }, {
    path: "/en/callback",
    component: _442485af,
    name: "callback___en"
  }, {
    path: "/en/contact",
    component: _1d238929,
    name: "contact___en"
  }, {
    path: "/en/dashboard",
    component: _72270b1d,
    name: "dashboard___en"
  }, {
    path: "/en/design-your-website",
    component: _9644968e,
    name: "design-your-website___en"
  }, {
    path: "/en/feature",
    component: _0b88cc7f,
    name: "feature___en"
  }, {
    path: "/en/home",
    component: _de783be8,
    name: "home___en"
  }, {
    path: "/en/landing-page",
    component: _40be2432,
    name: "landing-page___en"
  }, {
    path: "/en/login",
    component: _10ba8d22,
    name: "login___en"
  }, {
    path: "/en/login-callback",
    component: _7afb953f,
    name: "login-callback___en"
  }, {
    path: "/en/logout-callback",
    component: _395f2ba4,
    name: "logout-callback___en"
  }, {
    path: "/en/owner",
    component: _71761f7c,
    name: "owner___en"
  }, {
    path: "/en/owner-details",
    component: _6b1975b1,
    name: "owner-details___en"
  }, {
    path: "/en/pricing",
    component: _52932d4f,
    name: "pricing___en"
  }, {
    path: "/en/property-manager-about",
    component: _5c2f60a2,
    name: "property-manager-about___en"
  }, {
    path: "/en/recommend",
    component: _cfd2c176,
    name: "recommend___en"
  }, {
    path: "/en/reset-password",
    component: _0d6930f4,
    name: "reset-password___en"
  }, {
    path: "/en/reset-user",
    component: _488c0073,
    name: "reset-user___en"
  }, {
    path: "/en/residents",
    component: _6bcfd6ec,
    name: "residents___en"
  }, {
    path: "/en/search",
    component: _6cfe81d6,
    name: "search___en"
  }, {
    path: "/en/success-register",
    component: _32337dda,
    name: "success-register___en"
  }, {
    path: "/en/verify-email",
    component: _ec699596,
    name: "verify-email___en"
  }, {
    path: "/en/video-library",
    component: _217ce4b2,
    name: "video-library___en"
  }, {
    path: "/owner/About",
    component: _6d9f36d2,
    name: "owner-About___th"
  }, {
    path: "/owner/Available",
    component: _1b1304d3,
    name: "owner-Available___th"
  }, {
    path: "/owner/Contact",
    component: _41266f2a,
    name: "owner-Contact___th"
  }, {
    path: "/owner/Services",
    component: _9ec46c78,
    name: "owner-Services___th"
  }, {
    path: "/owner/Team",
    component: _31eefb43,
    name: "owner-Team___th"
  }, {
    path: "/owner/Testimonial",
    component: _c482e1ae,
    name: "owner-Testimonial___th"
  }, {
    path: "/owner/Why-us",
    component: _08321129,
    name: "owner-Why-us___th"
  }, {
    path: "/payment/status",
    component: _01a91105,
    name: "payment-status___th"
  }, {
    path: "/dashboard/admin/add-property",
    component: _becd4f46,
    name: "admin-add-property___th"
  }, {
    path: "/dashboard/admin/add-vendor",
    component: _568f0a50,
    name: "add-vendor___th"
  }, {
    path: "/dashboard/manager/add-contact",
    component: _7cd3fe48,
    name: "add-contact___th"
  }, {
    path: "/dashboard/manager/add-maintenance",
    component: _d762588a,
    name: "add-maintenance___th"
  }, {
    path: "/dashboard/manager/add-owner",
    component: _6819b21c,
    name: "add-owner___th"
  }, {
    path: "/dashboard/manager/add-property",
    component: _becd4f46,
    name: "add-property___th"
  }, {
    path: "/dashboard/manager/add-tenant",
    component: _1d096211,
    name: "add-tenant___th"
  }, {
    path: "/dashboard/manager/add-vendor",
    component: _568f0a50,
    name: "add-vendor___th"
  }, {
    path: "/dashboard/manager/create-holiday-greetings",
    component: _7c04f807,
    name: "create-holiday-greetings___th"
  }, {
    path: "/dashboard/manager/create-marketing",
    component: _52f4ac5e,
    name: "create-marketing___th"
  }, {
    path: "/dashboard/manager/create-report",
    component: _d4b462ce,
    name: "create-report___th"
  }, {
    path: "/en/create/AddContact",
    component: _3ada8bd8,
    name: "create-AddContact___en"
  }, {
    path: "/en/create/AddMaintenance",
    component: _ab97cd6a,
    name: "create-AddMaintenance___en"
  }, {
    path: "/en/create/AddProperty",
    component: _b9370666,
    name: "create-AddProperty___en"
  }, {
    path: "/en/create/AddVendor",
    component: _7db88ac0,
    name: "create-AddVendor___en"
  }, {
    path: "/en/create/CreateHolidayGreetings",
    component: _9558a4d2,
    name: "create-CreateHolidayGreetings___en"
  }, {
    path: "/en/create/CreateMarketing",
    component: _7d491641,
    name: "create-CreateMarketing___en"
  }, {
    path: "/en/create/CreateReport",
    component: _27818fae,
    name: "create-CreateReport___en"
  }, {
    path: "/en/create/EditLeaseDraft",
    component: _f8d5d1ac,
    name: "create-EditLeaseDraft___en"
  }, {
    path: "/en/create/EditListingAgreement",
    component: _3a2a7be9,
    name: "create-EditListingAgreement___en"
  }, {
    path: "/en/create/EditPurchaseSale",
    component: _2e7c9d6b,
    name: "create-EditPurchaseSale___en"
  }, {
    path: "/en/create/EditRegistrationLetter",
    component: _08a5b702,
    name: "create-EditRegistrationLetter___en"
  }, {
    path: "/en/create/EditSecurityDocumemt",
    component: _7f751d42,
    name: "create-EditSecurityDocumemt___en"
  }, {
    path: "/en/owner/About",
    component: _6d9f36d2,
    name: "owner-About___en"
  }, {
    path: "/en/owner/Available",
    component: _1b1304d3,
    name: "owner-Available___en"
  }, {
    path: "/en/owner/Contact",
    component: _41266f2a,
    name: "owner-Contact___en"
  }, {
    path: "/en/owner/Services",
    component: _9ec46c78,
    name: "owner-Services___en"
  }, {
    path: "/en/owner/Team",
    component: _31eefb43,
    name: "owner-Team___en"
  }, {
    path: "/en/owner/Testimonial",
    component: _c482e1ae,
    name: "owner-Testimonial___en"
  }, {
    path: "/en/owner/Why-us",
    component: _08321129,
    name: "owner-Why-us___en"
  }, {
    path: "/en/payment/status",
    component: _01a91105,
    name: "payment-status___en"
  }, {
    path: "/en/dashboard/admin/add-property",
    component: _becd4f46,
    name: "admin-add-property___en"
  }, {
    path: "/en/dashboard/admin/add-vendor",
    component: _568f0a50,
    name: "add-vendor___en"
  }, {
    path: "/en/dashboard/manager/add-contact",
    component: _7cd3fe48,
    name: "add-contact___en"
  }, {
    path: "/en/dashboard/manager/add-maintenance",
    component: _d762588a,
    name: "add-maintenance___en"
  }, {
    path: "/en/dashboard/manager/add-owner",
    component: _6819b21c,
    name: "add-owner___en"
  }, {
    path: "/en/dashboard/manager/add-property",
    component: _becd4f46,
    name: "add-property___en"
  }, {
    path: "/en/dashboard/manager/add-tenant",
    component: _1d096211,
    name: "add-tenant___en"
  }, {
    path: "/en/dashboard/manager/add-vendor",
    component: _568f0a50,
    name: "add-vendor___en"
  }, {
    path: "/en/dashboard/manager/create-holiday-greetings",
    component: _7c04f807,
    name: "create-holiday-greetings___en"
  }, {
    path: "/en/dashboard/manager/create-marketing",
    component: _52f4ac5e,
    name: "create-marketing___en"
  }, {
    path: "/en/dashboard/manager/create-report",
    component: _d4b462ce,
    name: "create-report___en"
  }, {
    path: "/en/dashboard/admin/edit-property/:id",
    component: _becd4f46,
    name: "admin-edit-property___en"
  }, {
    path: "/en/dashboard/admin/edit-vendor/:id",
    component: _568f0a50,
    name: "edit-vendor___en"
  }, {
    path: "/en/dashboard/manager/edit-contact/:id",
    component: _7cd3fe48,
    name: "edit-contact___en"
  }, {
    path: "/en/dashboard/manager/edit-holiday-greetings/:id",
    component: _7c04f807,
    name: "edit-holiday-greetings___en"
  }, {
    path: "/en/dashboard/manager/edit-lease-draft/:id",
    component: _6dafd19a,
    name: "edit-lease-draft___en"
  }, {
    path: "/en/dashboard/manager/edit-listing-agreement/:id",
    component: _148ba259,
    name: "edit-listing-agreement___en"
  }, {
    path: "/en/dashboard/manager/edit-maintenance/:id",
    component: _d762588a,
    name: "edit-maintenance___en"
  }, {
    path: "/en/dashboard/manager/edit-marketing/:id",
    component: _52f4ac5e,
    name: "edit-marketing___en"
  }, {
    path: "/en/dashboard/manager/edit-owner/:id",
    component: _6819b21c,
    name: "edit-owner___en"
  }, {
    path: "/en/dashboard/manager/edit-property/:id",
    component: _becd4f46,
    name: "edit-property___en"
  }, {
    path: "/en/dashboard/manager/edit-purchase-sale/:id",
    component: _065b084a,
    name: "edit-purchase-sale___en"
  }, {
    path: "/en/dashboard/manager/edit-registration-letter/:id",
    component: _6151fd1c,
    name: "edit-registration-letter___en"
  }, {
    path: "/en/dashboard/manager/edit-report/:id",
    component: _d4b462ce,
    name: "edit-report___en"
  }, {
    path: "/en/dashboard/manager/edit-security-document/:id",
    component: _cab2d062,
    name: "edit-security-document___en"
  }, {
    path: "/en/dashboard/manager/edit-tenant/:id",
    component: _1d096211,
    name: "edit-tenant___en"
  }, {
    path: "/en/dashboard/manager/edit-vendor/:id",
    component: _568f0a50,
    name: "edit-vendor___en"
  }, {
    path: "/dashboard/admin/edit-property/:id",
    component: _becd4f46,
    name: "admin-edit-property___th"
  }, {
    path: "/dashboard/admin/edit-vendor/:id",
    component: _568f0a50,
    name: "edit-vendor___th"
  }, {
    path: "/dashboard/manager/edit-contact/:id",
    component: _7cd3fe48,
    name: "edit-contact___th"
  }, {
    path: "/dashboard/manager/edit-holiday-greetings/:id",
    component: _7c04f807,
    name: "edit-holiday-greetings___th"
  }, {
    path: "/dashboard/manager/edit-lease-draft/:id",
    component: _6dafd19a,
    name: "edit-lease-draft___th"
  }, {
    path: "/dashboard/manager/edit-listing-agreement/:id",
    component: _148ba259,
    name: "edit-listing-agreement___th"
  }, {
    path: "/dashboard/manager/edit-maintenance/:id",
    component: _d762588a,
    name: "edit-maintenance___th"
  }, {
    path: "/dashboard/manager/edit-marketing/:id",
    component: _52f4ac5e,
    name: "edit-marketing___th"
  }, {
    path: "/dashboard/manager/edit-owner/:id",
    component: _6819b21c,
    name: "edit-owner___th"
  }, {
    path: "/dashboard/manager/edit-property/:id",
    component: _becd4f46,
    name: "edit-property___th"
  }, {
    path: "/dashboard/manager/edit-purchase-sale/:id",
    component: _065b084a,
    name: "edit-purchase-sale___th"
  }, {
    path: "/dashboard/manager/edit-registration-letter/:id",
    component: _6151fd1c,
    name: "edit-registration-letter___th"
  }, {
    path: "/dashboard/manager/edit-report/:id",
    component: _d4b462ce,
    name: "edit-report___th"
  }, {
    path: "/dashboard/manager/edit-security-document/:id",
    component: _cab2d062,
    name: "edit-security-document___th"
  }, {
    path: "/dashboard/manager/edit-tenant/:id",
    component: _1d096211,
    name: "edit-tenant___th"
  }, {
    path: "/dashboard/manager/edit-vendor/:id",
    component: _568f0a50,
    name: "edit-vendor___th"
  }, {
    path: "/en/help-center/:name?",
    component: _f561e87c,
    name: "help-center-name___en"
  }, {
    path: "/en/listing/:id",
    component: _f4ab0210,
    name: "listing-id___en"
  }, {
    path: "/en/payment/:id?",
    component: _39a789b7,
    name: "payment-id___en"
  }, {
    path: "/en/listing/:id?/apply-for-rent",
    component: _3e17cdf4,
    name: "listing-id-apply-for-rent___en"
  }, {
    path: "/en/listing/:id?/index_bk",
    component: _4dcc71c0,
    name: "listing-id-index_bk___en"
  }, {
    path: "/en/dashboard/:type/:component?",
    component: _096b7939,
    name: "dashboard-type-component___en"
  }, {
    path: "/en/:name",
    component: _743c2773,
    name: "name___en"
  }, {
    path: "/help-center/:name?",
    component: _f561e87c,
    name: "help-center-name___th"
  }, {
    path: "/listing/:id",
    component: _f4ab0210,
    name: "listing-id___th"
  }, {
    path: "/payment/:id?",
    component: _39a789b7,
    name: "payment-id___th"
  }, {
    path: "/en/:name/About",
    component: _681326e4,
    name: "name-About___en"
  }, {
    path: "/en/:name/Available",
    component: _89d38d6c,
    name: "name-Available___en"
  }, {
    path: "/en/:name/Contact",
    component: _aaeb553e,
    name: "name-Contact___en"
  }, {
    path: "/en/:name/Services",
    component: _73b6aa6d,
    name: "name-Services___en"
  }, {
    path: "/en/:name/Team",
    component: _e646cf28,
    name: "name-Team___en"
  }, {
    path: "/en/:name/Testimonial",
    component: _49c681e0,
    name: "name-Testimonial___en"
  }, {
    path: "/en/:name/Why-us",
    component: _5e2d0812,
    name: "name-Why-us___en"
  }, {
    path: "/listing/:id?/apply-for-rent",
    component: _3e17cdf4,
    name: "listing-id-apply-for-rent___th"
  }, {
    path: "/listing/:id?/index_bk",
    component: _4dcc71c0,
    name: "listing-id-index_bk___th"
  }, {
    path: "/en/:name/alerts/:id?",
    component: _b7526f1e,
    name: "name-alerts-id___en"
  }, {
    path: "/en/:name/listing/:id",
    component: _4dabbf93,
    name: "name-listing-id___en"
  }, {
    path: "/en/:name/listing/:id?/apply-for-rent",
    component: _625b526a,
    name: "name-listing-id-apply-for-rent___en"
  }, {
    path: "/en/:name/listing/:id?/index_bk",
    component: _ba5206b6,
    name: "name-listing-id-index_bk___en"
  }, {
    path: "/dashboard/:type/:component?",
    component: _096b7939,
    name: "dashboard-type-component___th"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___th"
  }, {
    path: "/:name",
    component: _743c2773,
    name: "name___th"
  }, {
    path: "/:name/About",
    component: _681326e4,
    name: "name-About___th"
  }, {
    path: "/:name/Available",
    component: _89d38d6c,
    name: "name-Available___th"
  }, {
    path: "/:name/Contact",
    component: _aaeb553e,
    name: "name-Contact___th"
  }, {
    path: "/:name/Services",
    component: _73b6aa6d,
    name: "name-Services___th"
  }, {
    path: "/:name/Team",
    component: _e646cf28,
    name: "name-Team___th"
  }, {
    path: "/:name/Testimonial",
    component: _49c681e0,
    name: "name-Testimonial___th"
  }, {
    path: "/:name/Why-us",
    component: _5e2d0812,
    name: "name-Why-us___th"
  }, {
    path: "/:name/alerts/:id?",
    component: _b7526f1e,
    name: "name-alerts-id___th"
  }, {
    path: "/:name/listing/:id",
    component: _4dabbf93,
    name: "name-listing-id___th"
  }, {
    path: "/:name/listing/:id?/apply-for-rent",
    component: _625b526a,
    name: "name-listing-id-apply-for-rent___th"
  }, {
    path: "/:name/listing/:id?/index_bk",
    component: _ba5206b6,
    name: "name-listing-id-index_bk___th"
  }],

  fallback: true
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
