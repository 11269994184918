import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=f1981e76&scoped=true&"
import script from "./dashboard.vue?vue&type=script&lang=js&"
export * from "./dashboard.vue?vue&type=script&lang=js&"
import style0 from "./dashboard.vue?vue&type=style&index=0&id=f1981e76&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1981e76",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DashboardSharedNav: require('/app/components/dashboard/shared/Nav.vue').default,UiSharedLang: require('/app/components/ui/shared/lang.vue').default,UiSharedErrorsErrorToast: require('/app/components/ui/shared/errors/ErrorToast.vue').default,DashboardSharedInquiryDialog: require('/app/components/dashboard/shared/InquiryDialog.vue').default,UiImageUpload: require('/app/components/ui/ImageUpload.vue').default,UiSharedDialogsDialogMBlur: require('/app/components/ui/shared/dialogs/DialogMBlur.vue').default})
