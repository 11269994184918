const actions = {
  async getDocumentsList({ commit }, params) {
    const res = await this.$repositories.documents.getDocumentsList(params)

    return res.data
  },
  async deleteDocument({ commit }, params) {
    const res = await this.$repositories.documents.deleteDocument(params)

    return res.data
  },
  async createLeaseDocument({ commit }, params) {
    const res = await this.$repositories.documents.createLeaseDocument(params)

    return res.data.data
  },
  async editLeaseDocument({ commit }, params) {
    const res = await this.$repositories.documents.editLeaseDocument(params)

    return res.data.data
  },
  async getLeaseData({ commit }, params) {
    const res = await this.$repositories.documents.getLeaseData(params)

    return res.data
  },
  async createPurchaseLeaseDocument({ commit }, params) {
    const res = await this.$repositories.documents.createPurchaseLeaseDocument(params)

    return res.data
  },
  async createListingDocument({ commit }, params) {
    const res = await this.$repositories.documents.createListingDocument(params)

    return res.data.data
  },
  async editListingDocument({ commit }, params) {
    const res = await this.$repositories.documents.editListingDocument(params)

    return res.data.data
  },
  async createSecurityDeposite({ commit }, params) {
    const res = await this.$repositories.documents.createSecurityDeposite(params)

    return res.data.data
  },
  async editSecurityDeposite({ commit }, params) {
    const res = await this.$repositories.documents.editSecurityDeposite(params)

    return res.data.data
  },
  async createRegistrationLetter({ commit }, params) {
    const res = await this.$repositories.documents.createRegistrationLetter(params)

    return res.data.data
  },
  async editRegistrationLetter({ commit }, params) {
    const res = await this.$repositories.documents.editRegistrationLetter(params)

    return res.data.data
  },
}

export default actions
