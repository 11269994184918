const controller = '/manager/dashboard/document'
export default axios => ({
  getDocumentsList(obj){
    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`
    if (obj.search) {
      parmas = parmas + `&search=${obj.search}`
    }
    if (obj.propertyId) {
      parmas = parmas + `&propertyId=${obj.propertyId}`
    }
    return axios.get(`${controller}${parmas}`)
  },
  deleteDocument(obj){

    return axios.delete(`${controller}/${obj.id}`)
  },
  createPurchaseLeaseDocument(obj) {
    return axios.post(`${controller}/purchase-and-lease-agreement-pdf`, obj)
  },
  createListingDocument(obj) {
    return axios.post(`${controller}/listing-agreement-pdf`, obj)
  },
  createSecurityDeposite(obj) {
    return axios.post(`${controller}/security-deposit-letter-pdf`, obj)
  },
  editSecurityDeposite(obj) {
    return axios.put(`${controller}/security-deposit-letter-pdf`, obj)
  },
  createRegistrationLetter(obj) {
    return axios.post(`${controller}/registration-letter-pdf`, obj)
  },
  editRegistrationLetter(obj) {
    return axios.put(`${controller}/registration-letter-pdf`, obj)
  },
  editListingDocument(obj) {
    return axios.put(`${controller}/listing-agreement-pdf`, obj)
  },
  createLeaseDocument(obj) {
    return axios.post(`${controller}/create-lease-agreement-pdf`, obj)
  },
  editLeaseDocument(obj) {
    return axios.put(`${controller}/create-lease-agreement-pdf`, obj)
  },
  getLeaseData(obj){
    return axios.get(`${controller}/edit-document/${obj.id}`, obj)
  }
})
